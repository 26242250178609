import './App.css';
import React from 'react';
import { BrowserRouter, Link, Route, Routes, useParams } from 'react-router-dom';
import Home from './Components/Home';
import Presentaion from './Components/Presentation';
import About from './Components/About';
import TrainingAuth from "./Components/TrainingAuth"
import Training from "./Components/TrainingHome"
import Testimonials from "./Components/Testimonials"
import Breakout1 from "./Components/Breakout1"
import Fergus1 from "./Components/Fergus1"
import Fergus2 from "./Components/Fergus2"
import Fergus3 from "./Components/Fergus3"
import Watertown from "./Components/Watertown"
import Warroad from "./Components/Warroad"
import Breakout2 from "./Components/Breakout2"
import Breakout3 from "./Components/Breakout3"
import Solutions from './Components/Solutions'
import ProtectedRoute from './Components/ProtectedRoute';
import db from './Firebase'
import { useState, useEffect } from 'react';
import { collection, doc, getDocs } from 'firebase/firestore';
import { AuthContextProvider } from './Context/authContext';
import AItools from './Components/AItools';
import PromptLibrary from './Components/PromptLibrary';
import AcitivityGenerator from './Components/ActivityGenerator'
import AIActivityGenerator from './Components/AIActivityGenerator';
import CritThinkAIProj from './Components/CritThinkAIProjGenerator';
import AIGroupProjectGenerator from './Components/AIGroupProjectGenerator';
import AIResearchProjectGenerator from './Components/AIResearchProjectGenerator';
import StudentSelfAssessmentTemplateGenerator from './Components/StudentSelfAssessmentTemplateGenerator';
import IdeaLibrary from './Components/IdeaLibrary';
import LessonPlanGenerator from './Components/LessonPlanGenerator';
import GroupProjectGenerator from './Components/GroupProjectGenerator';
import SalesPage from './Components/SalesPage';
import PresentationSales from './Components/PresentationSales';
import AutoGradeEssay from './Components/AutoGradeEssay';
import AutoGradeMulti from './Components/AutoGradeMulti';
import AutoGradeShort from './Components/AutoGradeShort';
import AutoGradeMath from './Components/AutoGradeMath';
import RubicGenerator from './Components/RubicGenerator';
import SyllabusGenerator from './Components/SyllabusGenerator';
import DayOneCourse from './Components/DayOneCourse';
import DayFiveCourse from './Components/DayFiveCourse';
import ViewPollResults from './Components/ViewPollResults';
import DayTwoCourse from './Components/DayTwoCourse';
import AdminProgressReport from './Components/AdminProgessReport';
import ProgressReport from './Components/ProgressReport';
import DayThreeCourse from './Components/DayThreeCourse';
import DayFourCourse from './Components/DayFourCourse';
import SuperSecretAdmin from './Components/SuperSecretAdmin';
import ResetPassword from './Components/ResetPassword';
import AdminCourse from './Components/AdminCourse'
import NDASApresentation from './Components/NDASApresentation';
import MASSP from './Components/MASSP'
import MASSPHumanCapitol from './Components/MASSPHumanCapitol';
import MASSPMiscellaneousNeeds from './Components/MASSPMiscellaneousNeeds';
import MASSPCommunication from './Components/MASSPCommunication';
import MASSPTeachingAndLearning from './Components/MASSPTeachingAndLearning';
import SCSC from './Components/SCSC'
import SCSCHumanCapitol from './Components/SCSCHumanCapitol';
import SCSCMiscellaneousNeeds from './Components/SCSCMiscellaneousNeeds';
import SCSCCommunication from './Components/SCSCCommunication';
import SCSCTeachingAndLearning from './Components/SCSCTeachingAndLearning';
import NDASAhumanCapitol from './Components/NDASAhumanCapitol';
import NDASAbusinessServices from './Components/NDASAbusinessServices';
import NDASAcommunication from './Components/NDASAcommunication';
import NDASAteachingAndLearning from './Components/NDASAteachingAndLearning';
import NDCELprincipals from './Components/NDCELprincipals';
import NDCELprincipalsHumanCapitol from './Components/NDCELprincipalsHumanCapitol';
import NDCELprincipalsMiscellaneousNeeds from './Components/NDCELprincipalsMiscellaneousNeeds';
import NDCELprincipalsCommunication from './Components/NDCELprincipalsCommunication';
import NDCELprincipalsTeachingAndLearning from './Components/NDCELprincipalsTeachingAndLearning';
import MASSPSW from './Components/MASSPSW';
import MASSPSWHumanCapitol from './Components/MASSPSWHumanCapitol';
import MASSPSWMiscellaneousNeeds from './Components/MASSPSWMiscellaneousNeeds';
import MASSPSWCommunication from './Components/MASSPSWCommunication';
import MASSPSWTeachingAndLearning from './Components/MASSPSWTeachingAndLearning';
import DPI from './Components/DPI';
import DPIHumanResources from './Components/DPIHumanResources';
import DPITeachingAndLearning from './Components/DPITeachingAndLearning';
import DPIbusinessServices from './Components/DPIbusinessServices';
import FargoSouth from './Components/FargoSouth';
import MCC from './Components/MCC';
import Canjo from './Components/Canjo'
import CanjoHumanCapitol from './Components/CanjoHumanCapitol';
import CanjoMiscellaneousNeeds from './Components/CanjoMiscellaneousNeeds';
import CanjoCommunication from './Components/CanjoCommunication';
import CanjoTeachingAndLearning from './Components/CanjoTeachingAndLearning';
import CanjoBusinessServices from './Components/CanjoBusinessServices';
import VAportal from './Components/VAportal';
import Sourcewell from './Components/Sourcewell';
import SourcewellBreakout1 from './Components/SourcewellBreakout1';
import SourcewellBreakout2 from './Components/SourcewellBreakout2';
import SourcewellBreakout3 from './Components/SourcewellBreakout3';
import OfficeStaff from './Components/OfficeStaff';
import OfficeStaffCommunication from './Components/OfficeStaffCommunication';
import OfficeStaffContentCreation from './Components/OfficeStaffContentCreation';
import OfficeStaffData from './Components/OfficeStaffData';
import MagicSchoolDeepDive from './Components/MagicSchoolDeepDive';
import AIResourceCenterforSchoolLeaders from './Components/LeadMagnet/AIResourceCenterforSchoolLeaders';


const App = () => {
  const [schools, setSchools] = useState([])
  const schoolsRef = collection(db, "School")

  useEffect(() => {
    const getSchools = async () => {
      const data = await getDocs(schoolsRef)
      setSchools(data.docs.map((doc) => ({
        ...doc.data(), id: doc.id
      })))
    }
    getSchools()
  }, [])

  return (
    <AuthContextProvider>
      <BrowserRouter>
        {schools.map((school) => (<Link to={`/training/${school.URL}`} />))}
        <Routes>
          <Route path="/" exact element={<Home />} />
          {/* <Route path="/day1course" exact element={<DayOneCourse />} />
          <Route path="/day2course" exact element={<DayTwoCourse />} />
          <Route path="/day3course" exact element={<DayThreeCourse />} />
          <Route path="/day4course" exact element={<DayFourCourse />} /> */}
          <Route path="/view-poll-results/:schoolURL/:dayNumber/:pollNumber/:question/:type" element={<ViewPollResults />} />
          <Route path="/adminProgressReport/:schoolURL" element={<AdminProgressReport />} />
          <Route path="/ProgressReport/:schoolURL" element={<ProgressReport />} />
          <Route path="/traininginfo" exact element={<SalesPage />} />
          <Route path="/presentationinfo" exact element={<PresentationSales />} />
          <Route path="/promptlibrary" exact element={<PromptLibrary />} />
          <Route path="/firebase" exact element={<ResetPassword />} />
          <Route path="/aitools" exact element={<AItools />} />
          <Route path="/watertown" exact element={<Watertown />} />
          <Route path="/warroad" exact element={<Warroad />} />
          <Route path="/breakout1" exact element={<Breakout1 />} />
          <Route path="/breakout2" exact element={<Breakout2 />} />
          <Route path="/breakout3" exact element={<Breakout3 />} />
          <Route path="/fergus1" exact element={<Fergus1 />} />
          <Route path="/fergus2" exact element={<Fergus2 />} />
          <Route path="/fergus3" exact element={<Fergus3 />} />
          <Route path="/fargosouth" exact element={<FargoSouth />} />
          <Route path="/mcc" exact element={<MCC />} />
          <Route path="/magicschooldeepdive" exact element={<MagicSchoolDeepDive />} />

          <Route path="/VAportal" exact element={<VAportal />} />\

          {/*LEAD MAGENR*/}
          <Route path="/AdminResourceCenter" exact element={<AIResourceCenterforSchoolLeaders />} />
          <Route path="/ndasa/humancapitol" exact element={<NDASAhumanCapitol />} />
          <Route path="/ndasa/businessservices" exact element={<NDASAbusinessServices />} />
          <Route path="/ndasa/communication" exact element={<NDASAcommunication />} />
          <Route path="/ndasa/teachingandlearning" exact element={<NDASAteachingAndLearning />} />


          {/*NDASA*/}
          <Route path="/ndasa" exact element={<NDASApresentation />} />
          <Route path="/ndasa/humancapitol" exact element={<NDASAhumanCapitol />} />
          <Route path="/ndasa/businessservices" exact element={<NDASAbusinessServices />} />
          <Route path="/ndasa/communication" exact element={<NDASAcommunication />} />
          <Route path="/ndasa/teachingandlearning" exact element={<NDASAteachingAndLearning />} />

          {/*NDCEL Principals*/}
          <Route path="/ndcelprincipals" exact element={<NDCELprincipals />} />
          <Route path="/ndcelprincipals/humancapitol" exact element={<NDCELprincipalsHumanCapitol />} />
          <Route path="/ndcelprincipals/miscellaneousneeds" exact element={<NDCELprincipalsMiscellaneousNeeds />} />
          <Route path="/ndcelprincipals/communication" exact element={<NDCELprincipalsCommunication />} />
          <Route path="/ndcelprincipals/teachingandlearning" exact element={<NDCELprincipalsTeachingAndLearning />} />

          {/*NMASSP SW*/}
          <Route path="/masspsw" exact element={<MASSPSW />} />
          <Route path="/masspsw/humancapitol" exact element={<MASSPSWHumanCapitol />} />
          <Route path="/masspsw/miscellaneousneeds" exact element={<MASSPSWMiscellaneousNeeds />} />
          <Route path="/masspsw/communication" exact element={<MASSPSWCommunication />} />
          <Route path="/masspsw/teachingandlearning" exact element={<MASSPSWTeachingAndLearning />} />

          {/*MASSP*/}
          <Route path="/massp/humancapitol" exact element={<MASSPHumanCapitol />} />
          <Route path="/massp/miscellaneousneeds" exact element={<MASSPMiscellaneousNeeds />} />
          <Route path="/massp/communication" exact element={<MASSPCommunication />} />
          <Route path="/massp/teachingandlearning" exact element={<MASSPTeachingAndLearning />} />

          {/*Sourcewell*/}
          <Route path="/sourcewell" exact element={<Sourcewell />} />
          <Route path="/sourcewell/breakout1" exact element={<SourcewellBreakout1 />} />
          <Route path="/sourcewell/breakout2" exact element={<SourcewellBreakout2 />} />
          <Route path="/sourcewell/breakout3" exact element={<SourcewellBreakout3 />} />

          {/*Office Staff */}
          <Route path="/officestaff" exact element={<OfficeStaff />} />
          <Route path="/officestaff/communication" exact element={<OfficeStaffCommunication />} />
          <Route path="/officestaff/contentcreation" exact element={<OfficeStaffContentCreation />} />
          <Route path="/officestaff/data" exact element={<OfficeStaffData />} />


          {/*SCSC*/}
          <Route path="/scsc" exact element={<SCSC />} />
          <Route path="/scsc/humancapitol" exact element={<SCSCHumanCapitol />} />
          <Route path="/scsc/miscellaneousneeds" exact element={<SCSCMiscellaneousNeeds />} />
          <Route path="/scsc/communication" exact element={<SCSCCommunication />} />
          <Route path="/scsc/teachingandlearning" exact element={<SCSCTeachingAndLearning />} />

          {/*CANJO*/}
          <Route path="/canjo" exact element={<Canjo />} />
          <Route path="/canjo/humancapitol" exact element={<CanjoHumanCapitol />} />
          <Route path="/canjo/miscellaneousneeds" exact element={<CanjoMiscellaneousNeeds />} />
          <Route path="/canjo/communication" exact element={<CanjoCommunication />} />
          <Route path="/canjo/teachingandlearning" exact element={<CanjoTeachingAndLearning />} />
          <Route path="/canjo/businessservices" exact element={<CanjoBusinessServices />} />

          {/*DPI*/}
          <Route path="/nddpi" exact element={<DPI />} />
          <Route path="/nddpi/humanresources" exact element={<DPIHumanResources />} />
          <Route path="/nddpi/businessservices" exact element={<DPIbusinessServices />} />
          <Route path="/nddpi/teachingandlearning" exact element={<DPITeachingAndLearning />} />

          <Route path="/massp" exact element={<MASSP />} />
          {/* <Route path="/idealibrary" exact element={<IdeaLibrary />} /> */}
          <Route path="/aitools/activitygenerator" exact element={<AcitivityGenerator />} />
          <Route path="/aitools/automatedgradingessays" exact element={<AutoGradeEssay />} />
          <Route path="/aitools/automatedgradingmultiplechoice" exact element={<AutoGradeMulti />} />
          <Route path="/aitools/automatedgradingshortanswer" exact element={<AutoGradeShort />} />
          <Route path="/aitools/automatedgradingmath" exact element={<AutoGradeMath />} />
          <Route path="/aitools/rubricgenerator" exact element={<RubicGenerator />} />
          <Route path="/aitools/syllabusgenerator" exact element={<SyllabusGenerator />} />
          <Route path="/aitools/aiassistedresearchproject" exact element={<AIResearchProjectGenerator />} />
          <Route path="/aitools/selfassessment" exact element={<StudentSelfAssessmentTemplateGenerator />} />
          <Route path="/aitools/aiactivitygenerator" exact element={<AIActivityGenerator />} />
          <Route path="/aitools/aifacilitatedgroupproject" exact element={<AIGroupProjectGenerator />} />
          <Route path="/aitools/lessonplangenerator" exact element={<LessonPlanGenerator />} />
          <Route path="/aitools/groupprojectgenerator" exact element={<GroupProjectGenerator />} />
          <Route path="/aitools/aisupportedcriticalthinkingandanalysisprojects" exact element={<CritThinkAIProj />} />
          <Route path="/training" element={<TrainingAuth />} />
          <Route path="/training/:id" element={<Training />} />
          {/* <Route path="/solutions" element={<Solutions />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/presentation" element={<Presentaion />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/training/:schoolID/day1" element={<DayOneCourse />} />
            <Route path="/training/:schoolID/day2" element={<DayTwoCourse />} />
            <Route path="/training/:schoolID/day3" element={<DayThreeCourse />} />
            <Route path="/training/:schoolID/day4" element={<DayFourCourse />} />
            <Route path="/training/:schoolID/day5" element={<DayFiveCourse />} />
            <Route path="/training/:schoolID/admin-training" element={<AdminCourse />} />
            <Route path="/supersectretadminpage" element={<SuperSecretAdmin />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>

  );
}

export default App;